/* eslint-disable deprecation/deprecation */
import { isNumber } from '@wistia/type-guards';
import { ControlInstances } from 'src/types/controls.ts';
import { PluginInstances } from 'src/types/plugins.ts';
import { StopGo } from '../../../../utilities/stopgo.js';
import { CrossTimeAurora } from '../../../../utilities/CrossTimeAurora.ts';
import type { WistiaPlayer } from '../../../wistiaPlayer/WistiaPlayer.tsx';
import type {
  EmbedOptions,
  MediaData,
  PublicApi,
  StopGoType,
} from '../../../../types/player-api-types.ts';
import { BetweenTimesAurora } from '../../../../utilities/BetweenTimesAurora.ts';
import { LOADED_MEDIA_DATA_EVENT } from '../../../../utilities/eventConstants.ts';
import { elemIsDescendantOf } from '../../../../utilities/elem.js';

type videoHeightOrWidthOptions = {
  constrain?: boolean;
};

export class TranslationApi {
  #_embeddedObserver: MutationObserver | undefined = undefined;

  #_uiContainer: Element | undefined = undefined;

  readonly #betweenTimesHandler: BetweenTimesAurora;

  readonly #commandQueueOpen: StopGoType;

  readonly #crossTimeHandler: CrossTimeAurora;

  readonly #wistiaPlayerComponent: WistiaPlayer;

  /**
   * @param wpComponent instance of the wistia-player component
   */
  public constructor(wpComponent: WistiaPlayer) {
    this.#wistiaPlayerComponent = wpComponent;

    const SG = StopGo as StopGoType;
    this.#commandQueueOpen = new SG();

    this.#commandQueueOpen(true);

    this.#crossTimeHandler = new CrossTimeAurora(
      this.#wistiaPlayerComponent,
      // eslint-disable-next-line @typescript-eslint/unbound-method
      this.unbind as () => void,
    );

    this.#betweenTimesHandler = new BetweenTimesAurora(
      this.#wistiaPlayerComponent,
      // eslint-disable-next-line @typescript-eslint/unbound-method
      this.unbind as () => void,
    );
  }

  public get _mediaData(): MediaData {
    return this.#wistiaPlayerComponent.mediaData;
  }

  /**
   * Often used in Wistia.api and helper methods
   * @returns {WistiaPlayer} returns the Aurora player component
   */
  public get container(): WistiaPlayer {
    return this.#wistiaPlayerComponent;
  }

  public get controls(): ControlInstances {
    return this.#wistiaPlayerComponent.controls;
  }

  // "plugin" in the old API was weird in that it was a function, but it also
  // acted as an object that contained all the plugin instances. To preserve
  // that form for any old usage, whenever we access the plugin property, we
  // mark it up with the references to the plugin instances.
  public get plugin():
    | PluginInstances
    | (<T extends keyof PluginInstances>(
        name: T,
        fn?: (name?, options?) => void,
      ) => Promise<PluginInstances[T]> | this) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    Object.keys(this.pluginFunction).forEach((key) => {
      this.pluginFunction[key] = undefined;
    });
    Object.keys(this.#wistiaPlayerComponent.plugins).forEach((key) => {
      this.pluginFunction[key] = this.#wistiaPlayerComponent.plugins[key];
    });
    // eslint-disable-next-line @typescript-eslint/unbound-method
    return this.pluginFunction;
  }

  /**
   * Often used in Wistia.api and helper methods
   * @returns {object} returns the Aurora player component
   */
  public get popover(): object {
    return {
      show: () => {
        this.#commandQueueOpen.synchronize((done) => {
          this.#wistiaPlayerComponent
            .showPopover()
            .then(() => {
              done();
            })
            .catch(() => {
              done();
            });
        });
      },
      hide: () => {
        this.#commandQueueOpen.synchronize((done) => {
          this.#wistiaPlayerComponent
            .hidePopover()
            .then(() => {
              done();
            })
            .catch(() => {
              done();
            });
        });
      },
      height: (newHeight?: number, options?: { constrain?: boolean }) => {
        // Since fixed styles can be set on the original popover embed container, we need
        // to set those alongside the usual styles on the wistia-player element
        const popoverContainer = this.#wistiaPlayerComponent.parentElement;
        if (!popoverContainer) {
          return this;
        }

        if (newHeight !== undefined) {
          popoverContainer.style.height = `${newHeight}px`;

          if (options?.constrain) {
            const widthForHeight = newHeight / this.#wistiaPlayerComponent.aspect;

            popoverContainer.style.width = `${widthForHeight}px`;
          }
        }

        return this.height(newHeight, options);
      },
      width: (newWidth?: number, options?: { constrain?: boolean }) => {
        // Since fixed styles can be set on the original popover embed container, we need
        // to set those alongside the usual styles on the wistia-player element
        const popoverContainer = this.#wistiaPlayerComponent.parentElement;
        if (!popoverContainer) {
          return this;
        }

        if (newWidth !== undefined) {
          popoverContainer.style.width = `${newWidth}px`;

          if (options?.constrain) {
            const widthForHeight = newWidth / this.#wistiaPlayerComponent.aspect;

            popoverContainer.style.height = `${widthForHeight}px`;
          }
        }

        return this.width(newWidth, options);
      },
    };
  }

  public async addPlugin(name: string, opts: object): Promise<unknown> {
    return this.#wistiaPlayerComponent.definePlugin(name, opts);
  }

  /**
   * Adds a media to the playlist.
   * @param {string} mediaId - the id of the media to add
   * @param {object} options - embed options to apply for this media in the playlist
   * @param {object} position - position to add the media in the playlist
   * @returns {Record<string, object | string>[]} - returns an array of media and their embed options in the playlist
   */
  public addToPlaylist(
    mediaId: string,
    options: object,
    position: object,
  ): Record<string, object | string>[] {
    return (
      this.#wistiaPlayerComponent.deprecatedApiDoNotUse?.addToPlaylist(
        mediaId,
        options,
        position,
      ) ?? []
    );
  }

  /**
   * Maps aspect property to the old aspect method
   * @returns {number} returns the aspect ratio of the video
   */
  public aspect(): number {
    return this.#wistiaPlayerComponent.aspect;
  }

  /**
   * bind doesn't and won't exist on wistia-player, so we need to call its PublicApi instance directly
   * @returns {Function} returns an unbind function
   */
  public bind(
    event: string,
    param1: number | (() => void),
    param2?: number | (() => void),
    param3?: () => void,
  ): this | undefined {
    switch (event) {
      case 'crosstime':
        this.#crossTimeHandler.addBinding(param1 as number, param2 as () => void);
        return this;

      case 'betweentimes':
        this.#betweenTimesHandler.addBinding(
          param1 as number,
          param2 as number,
          param3 as () => void,
        );
        return this;
      default:
        this.#wistiaPlayerComponent.deprecatedApiDoNotUse?.bind(event, param1);
        return this;
    }
  }

  /**
   * Maps cancelFullscreen method to the legacy cancelFullscreen method
   * @returns {this} .
   */
  public cancelFullscreen(): this {
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent
        .cancelFullscreen()
        .then(() => {
          done();
        })
        // old api always continued with the synchronize, even if cancelFullscreen fails
        .catch(() => {
          done();
        });
    });

    return this;
  }

  /**
   * Maps duration property to the old duration method
   * @returns {number} returns the duration of the media in seconds
   */
  public duration(): number {
    return this.#wistiaPlayerComponent.duration;
  }

  /**
   * Maps email property to the old email method
   * @param {string | undefined} newEmail - the new email to save
   * @returns {string} returns the email currently associated with this viewer
   */
  public email(newEmail: string | undefined): string | this | null {
    // Get
    if (newEmail === undefined) {
      return this.#wistiaPlayerComponent.email ?? null;
    }

    // Set
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent.email = newEmail;
      done();
    });

    return this;
  }

  /**
   *
   * <wistia-player> does not have an exact corollary to embedded. So we set up a MutationObserver looking for
   * children of the ui container, which is when embedded(true) fired with the legacy stopgo.
   *
   * @callback callbackFn
   * @param {callbackFn} callbackFn - callback function
   */
  public embedded(callbackFn?: () => void): boolean | this {
    const uiContainer = this.#wistiaPlayerComponent.shadowRoot?.querySelector('.w-ui-container');

    // if no callback has been given, we return a quick boolean check
    if (!callbackFn) {
      return uiContainer?.hasChildNodes() ?? false;
    }

    // if the uiContainer is setup and has children, we're considered embedded
    if (uiContainer?.hasChildNodes()) {
      callbackFn();

      return this;
    }

    // if the uiContainer already, we can set this since it won't appear in the MutationObserver
    if (uiContainer) {
      this.#_uiContainer = uiContainer;
    }

    // watching for descendants of the UiContainer - once that is populated, we're considered embedded
    this.#_embeddedObserver = new MutationObserver((mutations: MutationRecord[]): void => {
      mutations.forEach((mutation) => {
        Array.from(mutation.addedNodes).forEach((node) => {
          if (node.nodeType === 1) {
            if ((node as Element).classList.contains('w-ui-container')) {
              this.#_uiContainer = node as HTMLElement;
            }

            if (elemIsDescendantOf(node, this.#_uiContainer)) {
              callbackFn();
              this.#_embeddedObserver?.disconnect();
              this.#_embeddedObserver = undefined;
              this.#_uiContainer = undefined;
            }
          }
        });
      });
    });
    const observerConfig = { subtree: true, childList: true };
    this.#_embeddedObserver.observe(this.#wistiaPlayerComponent.shadowRoot as Node, observerConfig);

    return this;
  }

  /**
   *
   * @param context the context to enter
   */
  public enterInputContext(context: string): void {
    void this.#wistiaPlayerComponent.enterInputContext(context);
  }

  public eventKey(): string | undefined {
    return this.#wistiaPlayerComponent.eventKey;
  }

  /**
   *
   * @param context the context to exit
   */
  public exitInputContext(context: string): void {
    void this.#wistiaPlayerComponent.exitInputContext(context);
  }

  /**
   *
   * @param {T extends keyof ControlInstances} name the handle name of the control to be retrieved
   * @returns {ControlInstances[T]} the control object
   */
  public getControl<T extends keyof ControlInstances>(name: T): ControlInstances[T] {
    return this.#wistiaPlayerComponent.controls[name];
  }

  /**
   *
   * @returns {string | undefined} the current input context
   */
  public getInputContext(): string | undefined {
    return this.#wistiaPlayerComponent.inputContext;
  }

  /**
   *
   * @returns {HTMLAudioElement | HTMLVideoElement | null} the media element
   */
  public getMediaElement(): HTMLAudioElement | HTMLVideoElement | null {
    return this.#wistiaPlayerComponent.shadowRoot?.querySelector('video, audio') ?? null;
  }

  /**
   * hasData method to execute a given callback when the video hasData
   * @callback callbackFn
   * @param {callbackFn} callbackFn - callback function
   */
  public hasData(callbackFn?: () => void): boolean | this {
    const hasExistingMediaData = Object.keys(this.#wistiaPlayerComponent.mediaData).length > 0;

    if (!callbackFn) {
      return hasExistingMediaData;
    }

    if (hasExistingMediaData) {
      callbackFn();
      return this;
    }

    this.#wistiaPlayerComponent.addEventListener(LOADED_MEDIA_DATA_EVENT, () => {
      callbackFn();
    });

    return this;
  }

  /**
   * Maps mediaId property to the old hashedId method
   * @returns {string} returns the hashed id of the video
   */
  public hashedId(): string {
    return this.#wistiaPlayerComponent.mediaId;
  }

  /**
   * Maps height of Aurora web component to the old height method
   * @returns {number} returns the height of the video
   * @param {number} val new height in px
   * @param {Object=} options options object
   * @param {boolean=} options.constrain whether or not to constrain the embed to maintain the aspect ratio
   */
  public height(val?: number, options: { constrain?: boolean } = {}): number | this {
    if (val != null && !Number.isNaN(val)) {
      this.#wistiaPlayerComponent.style.height = `${val}px`;

      this.#executeAllEventBindings('heightchange');

      if (options.constrain) {
        const widthForHeight = val * this.#wistiaPlayerComponent.aspect;

        this.#wistiaPlayerComponent.style.width = `${widthForHeight}px`;
        this.#executeAllEventBindings('widthchange');
      }

      return this;
    }
    return this.#wistiaPlayerComponent.getBoundingClientRect().height;
  }

  /**
   * Maps inFullscreen property to the old inFullscreen method
   * @returns {boolean} returns whether the video is in fullscreen
   */
  public inFullscreen(): boolean {
    return this.#wistiaPlayerComponent.inFullscreen;
  }

  /**
   *
   * @returns {boolean} returns whether instantHLS is enabled for the video
   */
  public isInstantHls(): boolean {
    return this.#wistiaPlayerComponent.instantHls;
  }

  /**
   * Maps muted property to the old isMuted method
   * @returns {boolean} returns whether the video is muted
   */
  public isMuted(): boolean {
    return this.#wistiaPlayerComponent.muted;
  }

  /**
   * @returns {this} returns an instance of the TranslationApi for chaining
   */
  public mute(): this {
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent.muted = true;
      done();
    });

    return this;
  }

  /**
   * Maps name property to the old name method
   * @returns {string} returns the name of the video
   */
  public name(): string | null {
    return this.#wistiaPlayerComponent.name ?? null;
  }

  /**
   * turns the promise version from the wistia-player into the old chainable version
   * @returns {this} returns an instance of the TranslationApi for chaining
   */
  public pause(): this {
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent
        .pause()
        .then(() => {
          done();
        })
        // old api always continued with the synchronize, even if pause failed
        .catch(() => {
          done();
        });
    });

    return this;
  }

  /**
   * Maps percentWatched property to the old percentWatched method
   * @returns {number} returns the percent of the video that has been watched as a decimal between 0 and 1
   */
  public percentWatched(): number {
    return this.#wistiaPlayerComponent.percentWatched;
  }

  /**
   * turns the promise version from the wistia-player into the old chainable version
   * @returns {this} returns an instance of the TranslationApi for chaining
   */
  public play(): this {
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent
        .play()
        .then(() => {
          done();
        })
        // old api always continued with the synchronize, even if play failed
        .catch(() => {
          done();
        });
    });

    return this;
  }

  /**
   * Maps playbackRate property to the old playbackRate method
   * @param {number | undefined} newRate - the new playback rate of the media
   * @returns {number} returns the current playback rate of the media
   */
  public playbackRate(newRate: number | undefined): number | this {
    // Get
    if (newRate === undefined) {
      return this.#wistiaPlayerComponent.playbackRate;
    }

    // Set
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent.playbackRate = newRate;
      done();
    });

    return this;
  }

  /**
   * In order to preserve the same timing executions for legacy embeds we hook up
   * hasData, embedded, and ready to their respective legacy public_api/impl StopGos.
   * At a later point, when the Aurora player no longer depends on the public_api to build
   * a player, we can convert these to use newer events
   *
   * Ready method to execute a given callback when the video is ready
   * @callback callbackFn
   * @param {callbackFn} callbackFn - callback function
   */
  public ready(callbackFn?: () => void): PublicApi | boolean {
    if (this.#wistiaPlayerComponent.deprecatedApiDoNotUse) {
      if (callbackFn) {
        return this.#wistiaPlayerComponent.deprecatedApiDoNotUse.ready(callbackFn);
      }
      return this.#wistiaPlayerComponent.deprecatedApiDoNotUse.ready();
    }
    const legacyReadyCallback = () => {
      this.#wistiaPlayerComponent.removeEventListener(
        'legacypublicapicreated',
        legacyReadyCallback,
      );
      this.#wistiaPlayerComponent.deprecatedApiDoNotUse?.ready(callbackFn);
    };

    this.#wistiaPlayerComponent.addEventListener('legacypublicapicreated', legacyReadyCallback);
    return false;
  }

  /**
   * @param {string} name name of the control
   * @returns {Promise}
   */
  public async releaseControls(name: string): Promise<void> {
    return this.#wistiaPlayerComponent.releaseControls(name);
  }

  /**
   * remove doesn't and won't exist on wistia-player, so we need to call its PublicApi instance directly
   * @param {object} opts - options to pass to the remove method
   * @returns {void}
   */
  public remove(opts?: object): void {
    this.#_embeddedObserver?.disconnect();
    this.#_embeddedObserver = undefined;
    this.#_uiContainer = undefined;

    this.#wistiaPlayerComponent.deprecatedApiDoNotUse?.remove(opts);
  }

  /**
   * @param {string} hashedId
   * @param {object} options
   * @returns {this}
   */
  public replaceWith(hashedId: string, options: EmbedOptions = {}): this {
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent
        .replaceWithMedia(hashedId, options)
        .then(() => {
          done();
        })
        .catch(() => {
          done();
        });
    });

    return this;
  }

  /**
   * @param {string} name name of the control
   * @returns {Promise}
   */
  public async requestControls(name: string): Promise<void> {
    return this.#wistiaPlayerComponent.requestControls(name);
  }

  /**
   * Maps requestFullscreen method to the legacy requestFullscreen method
   * @returns {this}
   */
  public requestFullscreen(): this {
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent
        .requestFullscreen()
        .then(() => {
          done();
        })
        // old api always continued with the synchronize, even if requestFullscreen fails
        .catch(() => {
          done();
        });
    });

    return this;
  }

  /**
   * Maps secondsWatched property to the old secondsWatched method
   * @returns {number} returns the number of unique seconds that have been watched for the video.
   */
  public secondsWatched(): number {
    return this.#wistiaPlayerComponent.secondsWatched;
  }

  /**
   * Maps secondsWatchedVector property to the old secondsWatchedVector method
   * @returns {number[]} returns an array where each index represents the number of times the viewer has watched each second of the video.
   */
  public secondsWatchedVector(): number[] {
    return this.#wistiaPlayerComponent.secondsWatchedVector;
  }

  /**
   *
   * @param {string} handle Handle the control
   * @param {boolean} enabled Boolean to enable or disable the control
   * @returns {this}
   */
  public setControlEnabled(handle: string, enabled: boolean): this {
    this.#commandQueueOpen.synchronize((done) => {
      if (enabled) {
        this.#wistiaPlayerComponent
          .enableControl(handle)
          .then(() => {
            done();
          })
          .catch(() => {
            done();
          });
      }

      if (!enabled) {
        this.#wistiaPlayerComponent
          .disableControl(handle)
          .then(() => {
            done();
          })
          .catch(() => {
            done();
          });
      }
      done();
    });
    return this;
  }

  /**
   * Maps state property to the old state method
   * @returns {string} returns the state of the video
   */
  public state(): string {
    return this.#wistiaPlayerComponent.state;
  }

  /**
   * Maps currentTime property to the old time method
   * @param {number | undefined} newTime - the new time to set the video to
   * @returns {number} returns the current time of the video
   */
  public time(newTime: number | undefined): number | this {
    // Get
    if (newTime === undefined) {
      return this.#wistiaPlayerComponent.currentTime;
    }

    // Set
    this.#commandQueueOpen.synchronize((done) => {
      const seekComplete = () => {
        this.#wistiaPlayerComponent.removeEventListener('seeked', seekComplete);
        done();
      };
      this.#wistiaPlayerComponent.addEventListener('seeked', seekComplete);
      this.#wistiaPlayerComponent.currentTime = newTime;
    });

    return this;
  }

  /**
   * unbind doesn't and won't exist on wistia-player, so we need to call its PublicApi instance directly
   * @returns {this} returns an instance of the TranslationApi for chaining
   */
  public unbind(
    event: string,
    param1: number | (() => void),
    param2?: number | (() => void),
    param3?: () => void,
  ): this | undefined {
    switch (event) {
      case 'crosstime':
        this.#crossTimeHandler.removeBinding(param1 as number, param2 as () => void);
        return this;

      case 'betweentimes':
        this.#betweenTimesHandler.removeBinding(
          param1 as number,
          param2 as number,
          param3 as () => void,
        );
        return this;
      default:
        this.#wistiaPlayerComponent.deprecatedApiDoNotUse?.unbind(event, param1);
        return this;
    }
  }

  /**
   * map legacy unmute method to Aurora web component
   * @returns {this} returns an instance of the TranslationApi for chaining
   */
  public unmute(): this {
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent.muted = false;

      done();
    });

    return this;
  }

  /**
   *
   * @param {number} val
   * @param {videoHeightOrWidthOptions} options
   * @returns {number | this | undefined}
   */
  public videoHeight(
    val: number,
    options: videoHeightOrWidthOptions = {},
  ): number | this | undefined {
    if (isNumber(val)) {
      if (this.#wistiaPlayerComponent.embedOptions.videoFoam !== true) {
        this.#wistiaPlayerComponent.style.height = `${val}px`;
        if (options.constrain) {
          const widthForHeight = val / this.#wistiaPlayerComponent.aspect;

          this.#wistiaPlayerComponent.style.width = `${widthForHeight}px`;
        } else {
          // eslint-disable-next-line no-console
          console.warn('setting `videoHeight` while `videoFoam` is enabled results in a no-op');
        }

        return this;
      }
    }
    return Number(getComputedStyle(this.#wistiaPlayerComponent).height);
  }

  /**
   * Maps videoQuality property to the old videoQuality method
   * @param {number | 'auto' | undefined} quality - the new quality for the video - number or string 'auto'
   * @returns {number | this | 'auto'} returns the quality of the video or the api itself when using as a setter
   */
  public videoQuality(quality: number | 'auto' | undefined): number | this | 'auto' {
    // Get
    if (quality === undefined) {
      return this.#wistiaPlayerComponent.videoQuality;
    }

    // Set
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent.videoQuality = quality;

      done();
    });

    return this;
  }

  /**
   *
   * @param {number} val
   * @param {videoHeightOrWidthOptions} options
   * @returns {number | this | undefined}
   */
  public videoWidth(
    val: number,
    options: videoHeightOrWidthOptions = {},
  ): number | this | undefined {
    if (isNumber(val)) {
      if (this.#wistiaPlayerComponent.embedOptions.videoFoam !== true) {
        this.#wistiaPlayerComponent.style.width = `${val}px`;
        if (options.constrain) {
          const heightForWidth = val / this.#wistiaPlayerComponent.aspect;

          this.#wistiaPlayerComponent.style.height = `${heightForWidth}px`;
        } else {
          // eslint-disable-next-line no-console
          console.warn('setting `videoWidth` while `videoFoam` is enabled results in a no-op');
        }

        return this;
      }
    }
    return Number(getComputedStyle(this.#wistiaPlayerComponent).width);
  }

  /**
   * translation method for the visitorKey method that talks directly to the wistia global
   * @returns {string | null} returns the visitor_key of the person watching the video.
   */
  public visitorKey(): string | null {
    if (window.Wistia?.visitorKey?.value() != null) {
      return window.Wistia.visitorKey.value();
    }

    return null;
  }

  /**
   * Maps volume property to the old volume method
   * @param {number | undefined} level - the new volume for the video - Number between 0 and 1
   * @returns {number} returns the volume of the video as a Number between 0 and 1
   */
  public volume(level: number | undefined): number | this {
    // Get
    if (level === undefined) {
      return this.#wistiaPlayerComponent.volume;
    }

    // Set
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent.volume = level;

      done();
    });

    return this;
  }

  /**
   *
   * @param {string} handle name of the control
   * @returns {Object=} returns the control object
   */
  public async whenControlMounted<T extends keyof ControlInstances>(
    handle: T,
  ): Promise<ControlInstances[T]> {
    return this.#wistiaPlayerComponent.whenControlMounted(handle);
  }

  /**
   * Maps width of Aurora web component to the old width method
   * @returns {number} returns the width of the video
   * @param {number} val new height in px
   * @param {Object=} options options object
   * @param {boolean=} options.constrain whether or not to constrain the embed to maintain the aspect ratio
   */
  public width(val?: number, options: { constrain?: boolean } = {}): number | this {
    if (val != null && !Number.isNaN(val)) {
      this.#wistiaPlayerComponent.style.width = `${val}px`;

      this.#executeAllEventBindings('widthchange');

      if (options.constrain) {
        const heightForWidth = val / this.#wistiaPlayerComponent.aspect;

        this.#wistiaPlayerComponent.style.height = `${heightForWidth}px`;
        this.#executeAllEventBindings('heightchange');
      }

      return this;
    }
    return this.#wistiaPlayerComponent.getBoundingClientRect().width;
  }

  // This is the internal representation of the player.plugin('pluginName') usage.
  // The actual plugin property is defined as a getter so we can make sure it also
  // has the plugin instances as properties.
  private pluginFunction<T extends keyof PluginInstances>(
    name: T,
    fn?: (name?, options?) => void,
  ): Promise<PluginInstances[T]> | this {
    if (!fn) {
      return this.#wistiaPlayerComponent.getPlugin(name);
    }

    this.#commandQueueOpen.synchronize((done) => {
      fn(this.#wistiaPlayerComponent.getPlugin(name));
      done();
    });

    return this;
  }

  #executeAllEventBindings(eventName: string): void {
    if (this.#wistiaPlayerComponent.deprecatedApiDoNotUse?._bindings?.[eventName]) {
      const bindings = this.#wistiaPlayerComponent.deprecatedApiDoNotUse._bindings[eventName];
      bindings.forEach((fn: (api) => void) => {
        fn(this);
      });
    }
  }
}
