import { CharacterEdgeStyleLabel } from 'src/embeds/media/players/vulcanV2Player/video/controls/CaptionsButtonControl/types.ts';

const ONE_POINT_FIVE = 1.5;

type PropsForGetTextShadowForCharacterEdgeStyle = {
  characterEdgeStyle?: CharacterEdgeStyleLabel;
  scale: number;
};

export const getTextShadowForCharacterEdgeStyle = ({
  characterEdgeStyle,
  scale,
}: PropsForGetTextShadowForCharacterEdgeStyle): string => {
  const onePx = `${1 * scale}px`;
  const onePointFivePx = `${ONE_POINT_FIVE * scale}px`;
  const twoPx = `${2 * scale}px`;
  const threePx = `${3 * scale}px`;
  const negativeOnePx = `${-1 * scale}px`;

  switch (characterEdgeStyle) {
    case 'Depressed':
      return `${onePx} ${onePx} #aaa, ${negativeOnePx} ${negativeOnePx} #222`;
    case 'Drop shadow':
      return `${onePx} ${onePx} ${onePx} #222, ${onePx} ${onePx} ${twoPx} #222, ${onePx} ${onePx} ${threePx} #222`;
    case 'Outline':
      return `0 0 ${onePx} #222, 0 0 ${onePx} #222, 0 0 ${onePx} #222, 0 0 ${onePx} #222, 0 0 ${onePx} #222, 0 0 ${onePx} #222`;
    case 'Raised':
      return `${onePx} ${onePx} 0 #222, ${onePointFivePx} ${onePointFivePx} 0 #222`;
    default:
      return '';
  }
};
