import { Gradient, isGradient } from '../types/gradient.ts';
import { Color } from './color.js';

const DEFAULT_ALPHA = 0.9;
const GRADIENT_COLOR_INDEX = 0;
const GRADIENT_PERCENTAGE_INDEX = 1;

const hexToRGBA = (hex: string, alpha = DEFAULT_ALPHA): string => {
  const color = new Color(hex);

  // the "as Color" here is because the implicit types are not quite right... because we're passing a value to the
  // alpha function and using it as a setter it returns the new color as a Color object, not a number or undefined
  // (which happens if you're using the function as a getter)
  const colorWithAlpha = color.alpha(alpha) as Color;

  return colorWithAlpha.toRgba();
};

const decimalToPercentage = (decimal: number): number => decimal * 100;

export const getCssGradient = (gradient: Gradient, alpha = DEFAULT_ALPHA): string | null => {
  if (gradient.colors.length === 0) {
    return null;
  }

  const areAllColorsValid = gradient.colors.every(
    (gradientColor) =>
      gradientColor[GRADIENT_COLOR_INDEX] &&
      gradientColor[GRADIENT_PERCENTAGE_INDEX] >= 0 &&
      gradientColor[GRADIENT_PERCENTAGE_INDEX] <= 1,
  );

  if (!areAllColorsValid) {
    return null;
  }

  return `linear-gradient(90deg, ${gradient.colors.map((gradientColor) => `${hexToRGBA(gradientColor[0], alpha)} ${decimalToPercentage(gradientColor[1])}%`).join(', ')})`;
};

export const getGradientColor = (gradient: Gradient, index = 0): string | null => {
  if (!isGradient(gradient)) {
    return null;
  }

  return gradient.colors[index][GRADIENT_COLOR_INDEX];
};
